@import url('https://rsms.me/inter/inter.css');
@import '../../style.scss';

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 120px;

  background-color: $color-primary;
}

.intro {
  .name {
    position: relative;
    z-index: 1;
    margin-bottom: 12px;
  
    letter-spacing: -5px;
    font-size: 7em;
    padding-right: 64px;
  }
  .name:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    background-color: $color-accent;
    pointer-events: none;
    z-index: -1;
    display: block;
  }
}

.socials {
  height: 50px;
  margin: 12px 0px;
  margin-bottom: 24px;
  display: flex;
  justify-content: row;
  
  .socialbutton {
    height: 50px;
    width: 50px;
    cursor: pointer;
    border: none;
    background: unset;
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    margin-right: 18px;
    
    &.github {
      background-image: url('../../img/git.png');
    }
    
    &.linkedin {
      background-image: url('../../img/linkedin.png');
    }
    
    &.mail {
      background-image: url('../../img/mail.png');
    }
  }
}

h2 {
  letter-spacing: -1px;
  font-weight: 300;
}

.description {
  margin-bottom: 8px;
}

.copy {
  font-family: 'Inter';
  margin-top: 16px;
  color: $color-text;
}

@media screen and (max-width: 800px) {
  .app {
    padding-left: 40px;
  }

  .intro {
    .name:after {
      height: 18px;
    }
    .name, .lastname {
      font-size: 4em;
    }
  }

  .socials {
    height: 40px;
    .socialbutton {
      height: 40px;
      width: 40px;
    }
  }

  .mail {
    font-size: 1.5em;
  }
}